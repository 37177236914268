<template>
  <div class="main">
    <div class="all-chart">
      <div class="all-people">
        <div class="number">{{ areaRatinAge.total }}</div>
        <div class="desc">老龄人口数量</div>
      </div>
      <div class="charts">
        <div class="chart1">
          <div id="oldrate"></div>
          <div class="desc">老龄化 {{ areaRatinAge.old.percent }}</div>
        </div>
        <div class="chart2">
          <div id="oldGender"></div>
          <div class="desc">
            男:{{ areaRatinAge.gender.MALE.percent }} 女:{{
              areaRatinAge.gender.FEMALE.percent
            }}
          </div>
        </div>
        <div class="chart3">
          <div id="urbanization"></div>
          <div class="desc">
            乡:{{ areaRatinAge.census.AGRICULTURE.percent }} 城:{{
              areaRatinAge.census.CITIZEN.percent
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  props: ['areaRatinAge'],
  watch: {
    areaRatinAge: {
      handler(newVal, oldVal) {
        console.log('newVal===>', newVal)
        if (newVal.old) {
          this.initChartOfOldRate()
        }
        if (newVal.gender) {
          this.initChartOfOldGender()
        }
        if (newVal.census) {
          this.initChartOfUrbanization()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initChartOfOldRate() {
      this.$nextTick(() => {
        var getvalue = Number(this.areaRatinAge.old.percent.split('%')[0])
        var reslut = 100 - Number(this.areaRatinAge.old.percent.split('%')[0])
        var oldRate = echarts.init(document.getElementById('oldrate'))
        let option = {
          color: ['#FF6F0E', '#1F2A64'],
          tooltip: {
            show: false,
          },
          series: [
            {
              hoverAnimation: false,
              type: 'pie',
              radius: ['55%', '90%'],
              label: {
                show: true,
                position: 'center',
                formatter: '老龄化',
                color: '#69B1FF',
                fontWeight: 'bold',
                //   fontSize: '0.58rem',
              },
              data: [
                { value: getvalue, name: '老龄化' },
                { value: reslut, name: '' },
              ],
            },
          ],
        }
        oldRate.setOption(option)
        window.addEventListener('resize', function () {
          oldRate.resize()
        })
      })
    },
    initChartOfOldGender() {
      this.$nextTick(() => {
        var male
        var female
        if (
          this.areaRatinAge.gender.MALE.percent === '0%' &&
          this.areaRatinAge.gender.FEMALE.percent === '0%'
        ) {
          male = 1
          female = 1
        } else {
          male = this.areaRatinAge.gender.MALE.num
          female = this.areaRatinAge.gender.FEMALE.num
        }
        var oldGender = echarts.init(document.getElementById('oldGender'))
        let option = {
          color: ['#FF6351', '#2DFF99'],
          tooltip: {
            show: false,
          },
          series: [
            {
              hoverAnimation: false,
              type: 'pie',
              radius: ['55%', '90%'],
              label: {
                show: true,
                position: 'center',
                formatter: '性别',
                color: '#69B1FF',
                fontWeight: 'bold',
                //   fontSize: '0.58rem',
              },
              data: [
                { value: male, name: '男' },
                { value: female, name: '女' },
              ],
            },
          ],
        }
        oldGender.setOption(option)
        window.addEventListener('resize', function () {
          oldGender.resize()
        })
      })
    },
    initChartOfUrbanization() {
      this.$nextTick(() => {
        var agriculture
        var citizen
        if (
          this.areaRatinAge.census.AGRICULTURE.percent == '0%' &&
          this.areaRatinAge.census.CITIZEN.percent == '0%'
        ) {
          agriculture = 1
          citizen = 1
        } else {
          agriculture = this.areaRatinAge.census.AGRICULTURE.total
          citizen = this.areaRatinAge.census.CITIZEN.total
        }
        var urbanization = echarts.init(document.getElementById('urbanization'))
        let option = {
          color: ['#9900FF ', '#43E6FF'],
          tooltip: {
            show: false,
          },
          series: [
            {
              hoverAnimation: false,
              type: 'pie',
              radius: ['55%', '90%'],
              label: {
                show: true,
                position: 'center',
                formatter: '城镇',
                color: '#69B1FF',
                fontWeight: 'bold',
                //   fontSize: '0.58rem',
              },
              data: [
                { value: agriculture, name: '乡' },
                { value: citizen, name: '镇' },
              ],
            },
          ],
        }
        urbanization.setOption(option)
        window.addEventListener('resize', function () {
          urbanization.resize()
        })
      })
    },
  },
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 9.45rem;
  background: #051137;
  box-shadow: inset 0px 0px 0.9rem 0px rgba(58, 95, 255, 0.5);
  border-radius: 0.08rem;
  border: 0.04rem solid rgba(80, 142, 255, 0.22);
  background: url('../../../../image/twoThreeImgs/left/populationOverview.png')
    0 0 no-repeat;
  background-size: 100% 100%;
}

.all-chart {
  height: 6.8rem;
  margin: 1.9rem 0.5rem 0.42rem;
  display: flex;
}
.all-people {
  height: 100%;
  width: 5.33rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.083rem;
  border: 0.042rem solid #213060;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.number {
  width: fit-content;
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
  line-height: 1.45rem;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.desc {
  width: fit-content;
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.66rem;
  margin: 0 auto;
  /* margin-top: 1.5rem; */
  margin-bottom: 0.83rem;
}
.charts {
  width: 17.8rem;
  height: 100%;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.083rem;
  border: 0.042rem solid #213060;
  margin-left: 0.42rem;
  display: flex;
}
.chart1,
.chart2,
.chart3 {
  width: 33%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
#oldrate,
#oldGender,
#urbanization {
  width: 100%;
  height: 80%;
}
</style>
